import axios from 'axios';
import { useWebApp } from 'vue-tg';

const user = useWebApp().initDataUnsafe?.user;

const telegramId = user?.id ?? 111;
const username = user?.username ?? 'username';

export async function getOrCreateUser(partner) {
    try {
        partner = partner === null || isNaN(partner) ? 0 : partner;
        const response = await axios.post('https://92.63.193.245:8088/getOrCreateUser', {
            telegramId,
            username,
            partner
        });
        return response.data;
    } catch (error) {
        return {error: 'Ошибка при получении пользователя'}
    }
}