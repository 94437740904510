<template>
  <div class="gamePage" v-if="this.appStore.user.id">
  <HeaderLayout :userData="userData" />
  <div class="pageContent">
    <router-view :raceData="raceData" :userData="userData" />
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
  </div>
  <MenuLayout />
  </div>
</template>

<script>
import HeaderLayout from '@/components/layouts/HeaderLayout.vue'
import MenuLayout from '@/components/layouts/MenuLayout.vue'
import MessageComponent from '@/components/MessageComponent.vue';
import { useAppStore } from '@/stores/app';
import { onMounted } from 'vue';
import { useWebApp } from 'vue-tg';


export default {
  name: 'App',
  components: {
    HeaderLayout,
    MenuLayout,
    MessageComponent
  },
  data() {
    return {
      socket: null,
      raceData: {
        status: 'waiting',
        horses: [
          { name: 'horse1', position: -20, delay: 1 },
          { name: 'horse2', position: -20, delay: 1 },
          { name: 'horse3', position: -20, delay: 1 },
          { name: 'horse4', position: -20, delay: 1 },
          { name: 'horse5', position: -20, delay: 1 },
          { name: 'horse6', position: -20, delay: 1 },
        ],
        timer: 0,
        gameId: 0,
        racePosition: 0
      },
      userData: {
        id: 0,
        username: 'name',
        horsehoe: 0,
        ticket: 0
      },
      showMessage: false,
      messageType: '',
      messageText: ''
    };
  },
  async created() {
    this.socket = new WebSocket('wss://92.63.193.245:8089');
    this.socket.onopen = () => {
      console.log('Соединение установлено');
      const userId = this.appStore.user.id;
      if (userId) {
        this.socket.send(JSON.stringify({ type: 'userId', userId: userId }));
      } else {
        this.showMessage = true;
        this.messageType = 'bad';
        this.messageText = 'ID пользователя не установлен';
      }
    };
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.raceData = data.raceData || this.raceData;
      this.userData = data.userData || this.userData;
    };
    this.socket.onerror = () => {
      this.showMessage = true;
      this.messageType = 'bad';
      this.messageText = 'Ошибка получения данных';
    };
    this.socket.onclose = () => {
      console.log('Соединение закрыто');
    };
  },
  methods: {
    clearMessage() {
      this.showMessage = false;
      this.messageType = '';
      this.messageText = '';
    }
  },
  setup(){
    const appStore = useAppStore();
    const tg = useWebApp();
    const urlParams = new URLSearchParams(window.location.search);
    appStore.init(urlParams.get('p'));
    onMounted(() => {
      tg.ready();
      tg.expand();
    });
    return{
      appStore
    }
  }
}
</script>

<style scoped>
@import '@/css/App.css';
</style>