import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import RaceView from '@/views/RaceView.vue'
import PackView from '@/views/PackView.vue'
import SettingsView from '@/views/SettingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      breadcrumb: 'Home'
    }
  },
  {
    path: '/race',
    name: 'Race',
    component: RaceView,
    meta: {
      breadcrumb: 'Race'
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: HomeView,
    meta: {
      breadcrumb: 'Tasks'
    }
  },
  {
    path: '/leaderboard',
    name: 'Rating',
    component: HomeView,
    meta: {
      breadcrumb: 'Rating'
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: HomeView,
    meta: {
      breadcrumb: 'Partners'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: {
      breadcrumb: 'Settings'
    }
  },
  {
    path: '/packs',
    name: 'Packs',
    component: PackView,
    meta: {
      breadcrumb: 'Pack'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
