<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" width="800px" height="800px" viewBox="0 0 198.631 198.632"
        xml:space="preserve">
            <path fill="var(--page-color3)" d="M179.482,119.315c-22.893,12.354-25.098-10.119-25.098-10.119c-18.932-2.199-33.018-23.351-33.018-23.351
		c-25.555,13.225-11.318,50.469-0.295,64.772c14.162,18.336,3.788,48.014,3.788,48.014s-57.724-4.682-87.189-41.701
		c-22.109-27.792-16.414-54.06-10.113-71.398c7.578-20.854,17.83-32.285,32.781-44.452c22.195-18.096,63.216-20.377,63.216-20.377
		c0.271-1.54,1.418-3.325,3.032-5.169c-4.912,0.156-10.905,0.555-17.738,1.433c-4.688,0.883-9.859,1.404-15.117,2.964
		c-5.358,1.146-10.805,3.002-16.373,5.157c-5.574,2.065-11.036,5-16.441,8.095c-5.125,3.546-10.571,6.868-14.875,11.553
		c-4.953,4.043-8.553,9.279-12.259,14.304c-3.854,4.921-5.754,10.911-8.629,16.11C23,80.67,21.794,86.359,20.178,91.558
		c-1.457,5.249-1.434,10.521-2.161,15.214c-0.618,4.74-1.099,9.091-0.756,12.992c0.121,3.877,0.225,7.258,0.304,10.036
		c0.157,5.58,0.254,8.76,0.254,8.76l-0.757,0.083c0,0-0.674-3.121-1.551-8.736c-0.411-2.837-0.907-6.254-1.487-10.179
		c-0.831-3.948-0.866-8.452-0.502-13.382c0.399-4.921,0.024-10.367,1.15-15.997c1.283-5.58,2.092-11.73,4.356-17.65
		c2.899-5.677,4.85-12.141,8.863-17.587c3.854-5.571,7.666-11.375,12.938-15.876c4.85-4.989,10.799-8.453,16.415-12.132
		c5.866-3.154,11.78-6.112,17.711-8.157c5.902-2.223,11.801-3.65,17.449-4.48c5.574-1.215,10.961-1.386,15.847-1.959
		c8.316-0.391,15.267,0,20.67,0.579C135.707,6.588,146.447,0,146.447,0c-2.205,2.188-3.092,14.092-3.092,14.092
		c2.199-3.958,9.244-7.936,9.244-7.936c-3.96,9.224,0,29.049,0,29.049c11.455,11.467,24.223,51.09,24.223,51.09l4.847,7.045
		C187.851,102.171,185.582,116.028,179.482,119.315z" />
    </svg>
</template>

<script>
export default {
    name: 'HorseIcon'
}
</script>