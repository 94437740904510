<template>
    <div class="pageItem">
        <h2>Настройки</h2>
    </div>
</template>
<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'SettingsView',
    setup() {
        useHead({
            title: 'Настройки'
        });
    },
}
</script>