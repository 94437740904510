<template>
    <svg width="800px" height="800px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25 2C5.45507 2 4 3.45508 4 5.25V22.7497C4 24.5446 5.45507 25.9997 7.25 25.9997H16.8177L14.661 23.8429C13.7823 22.9643 13.7823 21.5396 14.661 20.661C15.5396 19.7823 16.9643 19.7823 17.8429 20.661L19.25 22.068L23.659 17.659C23.7653 17.5527 23.8796 17.4592 24 17.3787V5.25C24 3.45507 22.5449 2 20.75 2H7.25ZM10.5 8.75C10.5 9.44036 9.94036 10 9.25 10C8.55964 10 8 9.44036 8 8.75C8 8.05964 8.55964 7.5 9.25 7.5C9.94036 7.5 10.5 8.05964 10.5 8.75ZM9.25 15.2498C8.55964 15.2498 8 14.6902 8 13.9998C8 13.3095 8.55964 12.7498 9.25 12.7498C9.94036 12.7498 10.5 13.3095 10.5 13.9998C10.5 14.6902 9.94036 15.2498 9.25 15.2498ZM9.25 20.5C8.55964 20.5 8 19.9404 8 19.25C8 18.5596 8.55964 18 9.25 18C9.94036 18 10.5 18.5596 10.5 19.25C10.5 19.9404 9.94036 20.5 9.25 20.5ZM12.75 8H19.25C19.6642 8 20 8.33579 20 8.75C20 9.16421 19.6642 9.5 19.25 9.5H12.75C12.3358 9.5 12 9.16421 12 8.75C12 8.33579 12.3358 8 12.75 8ZM12 13.9998C12 13.5856 12.3358 13.2498 12.75 13.2498H19.25C19.6642 13.2498 20 13.5856 20 13.9998C20 14.414 19.6642 14.7498 19.25 14.7498H12.75C12.3358 14.7498 12 14.414 12 13.9998ZM12.75 18.5H19.25C19.6642 18.5 20 18.8358 20 19.25C20 19.6642 19.6642 20 19.25 20H12.75C12.3358 20 12 19.6642 12 19.25C12 18.8358 12.3358 18.5 12.75 18.5Z"
        fill="var(--page-color3)"/>
        <path d="M19.7803 25.7803L25.7803 19.7803C26.0732 19.4874 26.0732 19.0126 25.7803 18.7197C25.4874 18.4268 25.0126 18.4268 24.7197 18.7197L19.25 24.1893L16.7823 21.7216C16.4894 21.4287 16.0145 21.4287 15.7216 21.7216C15.4287 22.0145 15.4287 22.4894 15.7216 22.7823L18.7197 25.7803C18.8603 25.921 19.0511 26 19.25 26C19.4489 26 19.6397 25.921 19.7803 25.7803Z" 
        fill="var(--page-color3)"/>
    </svg>
</template>

<script>
export default {
    name: 'TaskIcon'
}
</script>