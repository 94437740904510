<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M46.8722 6H25.2942V15.6462C25.2942 16.8919 24.2697 17.882 23.0532 17.882C21.8366 17.882 20.8122 16.8919 20.8122 15.6462V6H17.0985C4.45272 6 0.290822 9.76901 0.00269026 21.5552C-0.0293243 22.1302 0.226793 22.7371 0.642982 23.1523C1.05917 23.5995 1.60342 23.823 2.24371 23.823C6.72575 23.823 10.4074 27.5283 10.4074 32C10.4074 36.4718 6.72575 40.1769 2.24371 40.1769C1.63543 40.1769 1.05917 40.4005 0.642982 40.8477C0.226793 41.2629 -0.0293243 41.8698 0.00269026 42.4447C0.290822 54.231 4.45272 58 17.0985 58H20.8122V48.3538C20.8122 47.1081 21.8366 46.118 23.0532 46.118C24.2697 46.118 25.2942 47.1081 25.2942 48.3538V58H46.8722C59.9982 58 64 54.0074 64 40.9116V23.0884C64 9.99263 59.9982 6 46.8722 6ZM52.6989 31.6806L49.7215 34.5553C49.5934 34.6511 49.5614 34.8428 49.5934 35.0025L50.2978 39.059C50.4258 39.7936 50.1377 40.5602 49.4974 41.0074C48.8891 41.4546 48.0888 41.5185 47.4165 41.1671L43.7348 39.2506C43.6067 39.1868 43.4146 39.1868 43.2866 39.2506L39.6049 41.1671C39.3168 41.3268 38.9966 41.3907 38.6765 41.3907C38.2603 41.3907 37.8761 41.2629 37.5239 41.0074C36.9157 40.5602 36.5955 39.8256 36.7236 39.059L37.4279 35.0025C37.4599 34.8428 37.3959 34.6831 37.2998 34.5553L34.3225 31.6806C33.7782 31.1696 33.5861 30.371 33.8103 29.6683C34.0344 28.9337 34.6426 28.4226 35.411 28.3268L39.5088 27.7199C39.6689 27.688 39.797 27.5922 39.893 27.4644L41.7179 23.7592C42.07 23.0884 42.7423 22.6732 43.5107 22.6732C44.279 22.6732 44.9513 23.0884 45.2715 23.7592L47.0963 27.4644C47.1603 27.6241 47.2884 27.7199 47.4485 27.7199L51.5463 28.3268C52.3147 28.4226 52.923 28.9656 53.1471 29.6683C53.4352 30.371 53.2431 31.1376 52.6989 31.6806Z"
            fill="url(#paint0_linear_1903_12)" />
        <defs>
            <linearGradient id="paint0_linear_1903_12" x1="32" y1="6" x2="32" y2="58" gradientUnits="userSpaceOnUse">
                <stop stop-color="var(--white)" />
                <stop offset="1" stop-color="var(--white2)" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TicketIcon'
}
</script>