<template>
    <div class="pageItem">
        <h2>Текущий забег</h2>
        <button>Тестовые звёзды</button>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'PackView',
    props: {
        raceData: {
            type: Object,
            required: true
        }
    },
    setup() {
        useHead({
            title: 'Приобретение билетов'
        });
    }
};
</script>