<template>
    <transition name="message-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div class="messageContainer" :class="messageClass" v-if="visible">
            <div class="message">
                <p class="messageIcon">
                    <svg v-if="type === 'bad'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                    </svg>
                </p>
                <p>{{ message }}</p>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ['bad', 'good'].includes(value);
            }
        },
        message: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            timeoutId: null // Добавляем идентификатор таймера
        };
    },
    computed: {
        messageClass() {
            return `_${this.type}`;
        }
    },
    watch: {
        message() {
            this.showMessage();
        }
    },
    methods: {
        showMessage() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            this.visible = true;
            this.timeoutId = setTimeout(() => {
                this.visible = false;
            }, 4000);
        },
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.transform = 'translateY(-100%)';
        },
        enter(el, done) {
            setTimeout(() => {
                el.style.transition = 'opacity 0.5s, transform 0.5s';
                el.style.opacity = 1;
                el.style.transform = 'translateY(0)';
                done();
            }, 400);
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.5s, transform 0.5s';
            el.style.opacity = 0;
            el.style.transform = 'translateY(-100%)';
            el.addEventListener('transitionend', done);
        }
    },
    mounted() {
        this.showMessage();
    },
    beforeUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
};
</script>