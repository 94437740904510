<template>
    <svg width="800px" height="800px" viewBox="0 0 24 24" id="leaderboard-podium-2"
        data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color">
        <path id="primary"
            d="M21,15H16V10a1,1,0,0,0-1-1H9a1,1,0,0,0-1,1v3H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V16A1,1,0,0,0,21,15Z"
            fill="var(--page-color3)"></path>
        <path id="secondary"
            d="M12.93,6.85a1,1,0,0,1-.47-.11L12,6.5l-.46.24a1,1,0,0,1-1.45-1.06l.09-.51L9.8,4.81a1,1,0,0,1,.56-1.71L10.87,3l.23-.47a1,1,0,0,1,1.8,0l.23.47.51.07a1,1,0,0,1,.56,1.71l-.38.36.09.51a1,1,0,0,1-.4,1A1,1,0,0,1,12.93,6.85Z"
            fill="var(--page-color3)"></path>
    </svg>
</template>

<script>
export default {
    name: 'LeaderboardIcon'
}
</script>