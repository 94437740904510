<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3796 7.67453C10.5401 7.86178 10.5401 8.13808 10.3796 8.32532L6.37963 12.992C6.19992 13.2017 5.88427 13.2259 5.6746 13.0462C5.46494 12.8665 5.44066 12.5509 5.62037 12.3412L9.34146 7.99993L5.62037 3.65866C5.44066 3.44899 5.46494 3.13334 5.6746 2.95363C5.88427 2.77392 6.19992 2.7982 6.37963 3.00787L10.3796 7.67453Z" fill="var(--gold)"/>
</svg>
</template>

<script>
export default {
    name: 'ArrowRight'
}
</script>