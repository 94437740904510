<template>
    <div class="pageItem">
        <h2>Текущий забег</h2>
        <p :class="['raceTimer', raceData.status]">{{ formattedStatus }}: {{ raceData.timer ? raceData.timer : 0 }}</p>
        <div class="raceOnline">
            <p v-for="(horse, index) in sortedHorses" :key="horse.name">
                {{ index + 1 }}. Лошадь <span :class="`horse${horse.name.slice(-1)}`">#{{ horse.name.slice(-1) }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'HomeView',
    props: {
        raceData: {
            type: Object,
            required: true
        }
    },
    computed: {
        sortedHorses() {
            return [...this.raceData.horses].sort((a, b) => b.position - a.position);
        },
        formattedStatus() {
            return this.raceData.status.charAt(0).toUpperCase() + this.raceData.status.slice(1);
        }
    },
    setup() {
        useHead({
            title: 'Главная страница'
        });
    }
};
</script>